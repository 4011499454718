export const REMATCH_REASONS = [
  "Freelancers were not a good fit for the proposal",
  "Very few freelancers were a good fit for the proposal",
  "Looking for a freelancer I know",
  "Want to see more candidates",
];

export const REMOVE_REASONS = [
  "Not a good fit, due to Industry fit",
  "Not a good fit, due to Experience fit",
  "Not a good fit, due to Software fit",
  "Not a good fit, due to Service line",
];

export const CHECKOUT_REASONS = [
  "Not a great fit",
  "Candidates too similiar",
  "Freelancer rate too high",
  "Not in the service line required",
];
export const REPORT_REASONS = [
  "Unresponsiveness",
  "Insufficient communication skills",
  "Lack of Professionalism",
];

export const isPilotVersion = (opportunityTypeMM) =>
  opportunityTypeMM === OPPORTUNITY_TYPES.WLSeasonal;

export const isServiceLinePlusEnabled = (opportunityTypeMM) =>
  [
    OPPORTUNITY_TYPES.WLSeasonal,
    OPPORTUNITY_TYPES.CrossSellNewExpertNewService,
  ].includes(opportunityTypeMM);

export const pitchLiteAvailability = (opportunityTypeMM) =>
  [OPPORTUNITY_TYPES.WLSeasonal, OPPORTUNITY_TYPES.Paro4CPA].includes(
    opportunityTypeMM
  );

/* To add a new widget:
1. add key value here,
2. use value in small case and map to a Component
3. update the component in:
  i. FreelancerFullProfileView
  ii. FreelancerProfileViewCreate
  iii. FreelancerProfileViewUpdate */
export const NEW_WIDGETS = ["EDUCATION", "PROJECTS", "WORK AND PREFERENCES"];

export const IS_PROD_ENVIRONMENT = !["", "development"].includes(
  process.env.REACT_APP_ENVIRONMENT_STAGE
);

export const FL_STATUSES = {
  Available: "AVAILABLE",
  "All Approved": "ALL_APPROVED",
  // Candidates: "CANDIDATES",
};

export const FAF_GROUPS = {
  Industries: "industries",
  Softwares: "softwares",
  Skills: "skills",
  "Service Lines": "serviceLine",
  "Service Lines Plus": "serviceLinesPlus",
  "Financial Tasks": "financialTasks",
  Certificates: "certificates",
  States: "state",
};

export const OPPORTUNITY_TYPES = {
  NewBusiness: "New Business",
  Upsell: "Upsell",
  Transition: "Transition",
  Urgent: "Urgent",
  Paro4CPA: "Paro4CPA",
  BuyOut: "Buy-out",
  WL: "Non CPA WL",
  WLSeasonal: "WL-Seasonal",
  CrossSellNewExpertNewService: "Cross-Sell - New Expert & New Service",
  CrossSellSameExpertNewService: "Cross-Sell - Same Expert & New Service",
  WinBack: "Win-Back",
  Downsell: "Downsell",
  Renewal: "Renewal",
};

export const ORIGINAL_OPPORTUNITY_TYPES = {
  NewBusiness: "New Business",
  Upsell: "Upsell",
  Transition: "Transition",
  CrossSellNewExpertNewService: "Cross-Sell - New Expert & New Service",
  CrossSellSameExpertNewService: "Cross-Sell - Same Expert & New Service",
  WinBack: "Win-Back",
  Downsell: "Downsell",
  Renewal: "Renewal",
};

export const isMM = (opportunityType) =>
  [
    OPPORTUNITY_TYPES.NewBusiness,
    OPPORTUNITY_TYPES.Upsell,
    OPPORTUNITY_TYPES.Transition,
    OPPORTUNITY_TYPES.Urgent,
    OPPORTUNITY_TYPES.Paro4CPA,
    OPPORTUNITY_TYPES.WL,
    OPPORTUNITY_TYPES.WLSeasonal,
    OPPORTUNITY_TYPES.CrossSellNewExpertNewService,
    OPPORTUNITY_TYPES.CrossSellSameExpertNewService,
    OPPORTUNITY_TYPES.WinBack,
    OPPORTUNITY_TYPES.Downsell,
    OPPORTUNITY_TYPES.Renewal,
  ].includes(opportunityType);

export const jobBoardOptionDisabled = (opportunityType) =>
  [
    OPPORTUNITY_TYPES.NewBusiness,
    OPPORTUNITY_TYPES.BuyOut,
    OPPORTUNITY_TYPES.Upsell,
  ].includes(opportunityType);

export const jobBoardOptionChecked = (opportunityType) =>
  [
    OPPORTUNITY_TYPES.NewBusiness,
    OPPORTUNITY_TYPES.WL,
    OPPORTUNITY_TYPES.WLSeasonal,
    OPPORTUNITY_TYPES.Paro4CPA,
    OPPORTUNITY_TYPES.CrossSellNewExpertNewService,
    OPPORTUNITY_TYPES.WinBack,
    OPPORTUNITY_TYPES.Downsell,
    OPPORTUNITY_TYPES.Renewal,
  ].includes(opportunityType);

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export const BUYOUT_FLID = () =>
  process.env.REACT_APP_ENVIRONMENT_STAGE === "dev" ? 36786 : 51898;

export const REACTIVATION_OPTIONS = [
  {
    groupName: "Expert Interested Email",
    options: [
      "No Expert(s) responded in time [Ghosted]",
      "All Experts declined",
      "As Rep, I declined - determined not right fit",
      "As Rep, I declined - bad quality of pitches",
    ],
  },
  {
    groupName: "Client Presentation",
    options: [
      "Expert(s) declined at this stage",
      "Expert(s) were unresponsive at this stage [Ghosted]",
      "Client declined presented Expert(s) at this stage",
    ],
  },
  {
    groupName: "Contracting (SOW)",
    options: [
      "Expert did not submit SOW of quality - Rejected",
      "Expert did not submit SOW in timely manner [Ghosted]",
      "Expert rejected SOW",
      "Client rejected SOW",
      "Client rejected Expert at the SOW stage",
      "Client did not respond/submit SOW in timely manner",
    ],
  },
  {
    groupName: "Others",
    options: [],
  },
];
export const SECONDARY_SERVICE_LINES = {
  CFO: ["FP&A", "CPA/Accounting Advisory"],
  "Full Charge Bookkeeping": ["Tax Preparation", "CPA/Accounting Advisory"],
  "FP&A": ["CFO", "CPA/Accounting Advisory"],
  "CPA/Accounting Advisory": ["Tax Preparation", "CFO"],
  "Tax Preparation": ["Full Charge Bookkeeping", "CPA/Accounting Advisory"],
};

export const MAX_COMPARE = 5;

export const COMPANY_SIZE_LIST = [
  "No Company Size",
  "1-10",
  "11-50",
  "51-250",
  "251-1K",
  "1K-5K",
  "5K-10K",
  "10K-50K",
  "50K-100K",
  "100K+",
];

export const INDUSTRY_LIST = [
  "",
  "Automotive",
  "Consumer Discretionary",
  "Consumer Goods",
  "Household Durables",
  "Leisure Products",
  "Textiles, Apparel & Luxury Goods",
  "Consumer Services",
  "Diversified Consumer Services",
  "Hotels, Restaurants & Leisure",
  "Education Services",
  "Family Services",
  "Specialized Consumer Services",
  "Media",
  "Distributors",
  "Retailing",
  "Specialty Retail",
  "Consumer Staples",
  "Food & Staples Retailing",
  "Beverages",
  "Food Products",
  "Tobacco",
  "Personal Products",
  "Gas Utilities",
  "Banks",
  "Diversified Financial Services",
  "Capital Markets",
  "Insurance",
  "Real Estate",
  "Health Care Equipment & Supplies",
  "Biotechnology",
  "Life Sciences Tools & Services",
  "Pharmaceuticals",
  "Aerospace & Defense",
  "Capital Goods",
  "Construction & Engineering",
  "Electrical Equipment",
  "Industrial Conglomerates",
  "Machinery",
  "Trading Companies & Distributors",
  "Commercial Services & Supplies",
  "Professional Services",
  "Industrials",
  "Air Freight & Logistics",
  "Airlines",
  "Marine",
  "Road & Rail",
  "Transportation",
  "Semiconductors & Semiconductor Equipment",
  "Internet Software & Services",
  "IT Services",
  "Software",
  "Communications Equipment",
  "Electronic Equipment, Instruments & Components",
  "Technology Hardware, Storage & Peripherals",
  "Building Materials",
  "Chemicals",
  "Containers & Packaging",
  "Metals & Mining",
  "Paper & Forest Products",
  "Diversified Telecommunication Services",
  "Wireless Telecommunication Services",
  "Renewable Electricity",
  "Electric Utilities",
  "Utilities",
];

export const getEstimatedBudget = (projectSize, clientRate) => {
  let estimatedBudget;
  switch (projectSize) {
    case "<10hrs":
      estimatedBudget = `$${clientRate / 2} - $${(clientRate / 2) * 10}`;
      break;
    case "51+":
      estimatedBudget = `${(clientRate / 2) * 51}+`;
      break;
    case "161+hrs":
      estimatedBudget = `${(clientRate / 2) * 161}+`;
      break;
    case "Unknown":
      estimatedBudget = "NA";
      break;
    case "":
      estimatedBudget = "NA";
      break;
    default:
      estimatedBudget = `$${
        (clientRate / 2) * (parseInt(projectSize?.split("-")[0]) || 1)
      } - $${
        (clientRate / 2) *
        (parseInt(projectSize?.split("-")[1]?.split("hrs")[0]) || 10)
      }`;
      break;
  }
  return estimatedBudget;
};

function getMinMax(range) {
  const regex = /(-?\d+)?-(-?\d+)?/;
  const match = range.match(regex);

  if (!match) {
    return { min: "", max: "" };
  }

  const num1 = parseInt(match[1] || 0, 10);
  const num2 = parseInt(match[2] || 0, 10);
  // Find the min and max values
  const minValue = Math.min(num1, num2);
  const maxValue = Math.max(num1, num2);

  return { min: minValue, max: maxValue };
}

export const getMinMaxProjectSize = (projectSize) => {
  let minHours = "";
  switch (projectSize) {
    case "<10hrs":
      break;
    case "51+":
      minHours = 51;
      break;
    case "161+hrs":
      minHours = 161;
      break;
    case "Unknown":
      break;
    case "":
      break;
    default:
      const { min } = getMinMax(projectSize);
      minHours = parseInt(min) || "";

      break;
  }
  return { minHours };
};

export const StageTextsMap = new Map([
  ["-", "Ready to Match"],
  ["New", "Freelancer(s) added to Communication Hub"],
  ["0", "Ready to Match"],
  ["Interested", "Freelancer(s) Interested"],
  ["ClientProposal", "Client Sent to Match"],
  ["IntroEmail", "Intro Email Sent"],
  ["SowSubmitted", "SOW Sent to Client"],
  ["SowAccepted", "Client Accepts SOW"],
  ["ClosedWon", "Project Won"],
  ["ClientRejected", "Client Rejected"],
  ["FlRejected", "Freelancer Rejects"],
]);

export const StageOptionsMap = new Map([
  ["-", "0"],
  ["New", "-1"],
  ["Proposed", "0"],
  ["Interested", "1"],
  ["ClientProposal", "2"],
  ["IntroEmail", "3"],
  ["SowSubmitted", "4"],
  ["SowAccepted", "5"],
  ["ClosedWon", "91"],
  ["ClientRejected", "92"],
  ["FlRejected", "93"],
]);

export const getRateType = (billRateType, frequency) => {
  if (billRateType === "Hourly") {
    return "/hour";
  }

  if (frequency === "One-Time" || frequency === "One Time") {
    return "/project";
  }

  if (frequency === "Recurring Monthly" || frequency === "Recurring monthly") {
    return "/month";
  }

  return "";
};

export const getEffortType = (frequency) => {
  const projectType = frequency?.toLowerCase();

  if (projectType === "one time" || projectType === "one-time") {
    return "/project";
  }

  if (projectType === "recurring monthly") {
    return "/month";
  }

  return "";
};
