import { useCallback, useEffect, useState } from "react";
import { getRenewalProposals } from "../services/apiService";
import { Grid } from "@material-ui/core";
import { Heading } from "@paro.io/base-ui";
import RenewalCardItem from "./RenewalCardItem";
import CustomSpinner from "./CustomSpinner";

export const RenewalsTabContent = ({
  currentSalesRep,
  setLoadingError,
  proposalId,
  renewalData,
  setRenewalData,
}) => {
  const [loading, setLoading] = useState(false);

  const refreshRenewalProposals = useCallback(async () => {
    setLoading(true);

    try {
      const renewalProposalData = await getRenewalProposals(
        currentSalesRep.value
      );
      setRenewalData(
        renewalProposalData.data.data.getRenewalProposals.map((renewal) => ({
          ...renewal.proposal,
          renewalProposalId: renewal.id,
          projectEndDate: renewal.endDate,
          projectStartDate: renewal.startDate,
          rejectedByUserId: renewal.rejectedByUserId,
          aeEmailSentAt: renewal.aeEmailSentAt,
        }))
      );
      setLoading(false);
    } catch (err) {
      setLoadingError(true);
    }
  }, [currentSalesRep.value, setLoadingError, setRenewalData]);

  useEffect(() => {
    if (!renewalData) {
      refreshRenewalProposals();
    }
  }, [currentSalesRep, refreshRenewalProposals, renewalData]);

  if (loading || !renewalData) {
    return <CustomSpinner>Fetching Renewal Proposals</CustomSpinner>;
  }

  return (
    <div className="px-8 md:px-8 lg:px-16">
      {renewalData && renewalData.length === 0 ? (
        <div className="flex justify-center p-8 mt-3 font-bold text-secondary">
          No Active Renewals
        </div>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <Heading size="h3">
              Renewal Proposals which are up for renewal
            </Heading>
          </Grid>
          <div className="py-4">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {renewalData.map((proposal) => (
                <RenewalCardItem
                  key={proposal.id}
                  proposal={proposal}
                  refetchProposals={refreshRenewalProposals}
                  showModal={Number(proposalId) === proposal.id}
                />
              ))}
            </div>
          </div>
        </Grid>
      )}
    </div>
  );
};
