import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller, useWatch, useFieldArray } from "react-hook-form";
import { makeStyles, TextField, FormLabel } from "@material-ui/core";
import SelectSoftware from "./SelectSoftware";
import SelectService from "./SelectService";
import SelectIndustry from "./SelectIndustry";
import SelectSkills from "./SelectSkills";
import SelectEffort from "./SelectEffort";
import SelectField from "./SelectField";
import { createQuestionsTemplate, deleteQuestionsTemplate, getAllQuestionsTemplates, updateProposal, updateQuestionsTemplate } from "../services/apiService";
import { useSnackbar } from "notistack";
import { Heading, Text, Button, Input } from "@paro.io/base-ui";
import {
  getKeyByValue,
  getRateType,
  isPilotVersion,
  isServiceLinePlusEnabled,
  OPPORTUNITY_TYPES,
  StageOptionsMap,
  getEstimatedBudget,
  getEffortType
} from "../data/appConstants";
import InfoButton from "./InfoButton";
import { useTags } from "../contexts/useTags";
import Gpt3Component from "./Gpt3Component";
import { IconCalendar, IconCheck, IconPlus, IconTrash, IconX } from "@paro.io/base-icons";
import SelectServiceLinesPlus from "./SelectServiceLinesPlus";
import SelectLangOrCert from "./SelectLangOrCert";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import colors from "../shared/constants/colors";
import MMSelect from "./MMSelect";
import { useAuth0 } from "../contexts/Auth0Context";
import ReactQuillEditor from "./QuillEditor";
import { MatchResultsContext } from "../contexts/MatchResultsContext";
import { transformProposalData } from "../utils/freelancerSearchService";
import { Disclosure } from "@headlessui/react";
import { ChevronRightRounded } from "@material-ui/icons";

const billingValues = {
  HOURLY: "Hourly",
  FIXED: "Fixed",
};

const occurenceValues = {
  ONETIME: "One-Time",
  RECURRING: "Recurring Monthly",
};

const SAVE_DEFAULT_OPTIONS = [
  {
    label: "Save to new template",
    value: 'new'
  },
  {
    label: "Save to existing template",
    value: 'existing'
  }
]

const useStyles = makeStyles((theme) => ({
  textArea: {
    minWidth: "100%",
  },
}));

export default function UpdateProposalForm({
  defaultValues,
  updateProposalFn,
}) {
  const [matchQuery, setMatchQuery] =
    useContext(MatchResultsContext)?.matchQueryContext;

  const [notes, setNotes] = useState(defaultValues.notes);
  const [deletedQuestionIds, setDeletedQuestionIds] = useState([]);
  const [saveQuestionType, setSaveQuestionType] = useState('');
  const [selectedTemplatedId, setSelectedTemplateId] = useState(null)
  const [selectedTemplatedIdForUpdate, setSelectedTemplateIdForUpdate] = useState(null)
  const [templates, setTemplates] = useState([])
  const [templateName, setTemplateName] = useState("")
  const [templateError, setTemplateError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [rateType, setRateType] = useState(
    getRateType(defaultValues.billRateType, defaultValues.frequency)
  );
  const [effortType, setEffortType] = useState(
    getEffortType(defaultValues.frequency)
  );
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedQuestionTemplate, setSelectedQuestionTemplate] = useState(null)
  const [questionErrors, setQuestionErrors] = useState([])

  const { user } = useAuth0();

  const { handleSubmit, control, setValue, register, getValues, formState, watch, trigger } =
    useForm({
      defaultValues: {
        ...defaultValues,
        earningsMinBudget: defaultValues.estimatedBudget?.split("-")[0]?.slice(1) ? Number(defaultValues.estimatedBudget?.split("-")[0]?.slice(1)) * 2 : null,
        earningsMaxBudget: defaultValues.estimatedBudget?.split("-")[1]?.slice(2) ? Number(defaultValues.estimatedBudget?.split("-")[1]?.slice(2)) * 2 : null,
        saveQuestion: '0',
        questions: defaultValues.questions?.length > 0
          ? defaultValues.questions.map((q) => ({
            savedid: q.id,
            value: q.question,
          }))
          : [{ value: "" }],
      },
      mode: "onChange",
    });

  const minBudget = watch("earningsMinBudget")
  const earningsMinBudget = minBudget ? Number(minBudget) : minBudget === 0 ? 0 : null;
  const maxBudget = watch("earningsMaxBudget")
  const earningsMaxBudget = maxBudget ? Number(maxBudget) : maxBudget === 0 ? 0 : null;

  const showExpertTakeHome =
    earningsMinBudget > 0 &&
    earningsMaxBudget > 0 &&
    earningsMinBudget <= earningsMaxBudget;

  const watchPitchRequired = useWatch({
    control,
    name: "pitchRequired",
  });

  const watchStartDateField = useWatch({
    control,
    name: "estimatedStartDate",
  });

  const watchFrequency = useWatch({
    control,
    name: "frequency",
  });

  const [estimatedBudget, setEstimatedBudget] = useState(
    getEstimatedBudget(defaultValues.projectSize, defaultValues.clientRate)
  );

  const opportunityTypeMM = useWatch({
    control,
    name: "opportunityTypeMM", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: defaultValues.opportunityTypeMM, // default value before the render
  });

  const watchFields = watch("questions");

  const {
    effortOptions,
    serviceOptions,
    softwareOptions,
    industryOptions,
    skillOptions,
    serviceLinesPlusOptions,
    languageOptions,
    certificationsOptions,
    teamsOptions
  } = useTags();

  const { enqueueSnackbar } = useSnackbar();
  const {
    fields: questionFields,
    append,
    remove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "questions", // unique name for your Field Array,
  });
  const classes = useStyles();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    try {
      const data = await getAllQuestionsTemplates(user?.userId)
      setTemplates(data?.data?.data?.getAllQuestionsTemplates)
    } catch (error) {
      console.log(error)
    }
  }, [user?.userId])

  const checkQuestionErrors = () => {
    let isErrorExist = false
    let qErrors = []
    watchFields?.forEach((q, idx) => {
      if (!q?.value?.trim().length) {
        isErrorExist = true
        qErrors.push(idx)
      }
    })

    if (isErrorExist) {
      setQuestionErrors(qErrors)
    } else {
      setQuestionErrors([])
    }

    return isErrorExist
  }

  const onSubmit = async (data) => {
    if (!watchPitchRequired) {
      const isError = checkQuestionErrors()
      if (isError) {
        return
      }
    }

    const formData = {
      ...data,
      billRateType: getKeyByValue(billingValues, data.billRateType),
      frequency: getKeyByValue(
        occurenceValues,
        data.frequency === "Recurring monthly" ? "RECURRING" : data.frequency
      ),
    };
    const industryTags = formData.industries.map((i) => ({
      field: "industries",
      value: i.value || i.inputValue,
      required: i.required,
    }));
    const softwareTags = formData.softwares.map((i) => ({
      field: "softwares",
      value: i.value || i.inputValue,
      required: i.required,
    }));
    const skillTags = formData.skills.map((i) => ({
      field: "skills",
      value: i.value || i.inputValue,
      required: i.required,
    }));
    const languageTags = formData?.language?.length > 0 ? formData?.language?.map((i) => ({
      field: "language",
      value: i.value || i.inputValue,
      required: i.required,
    })) : []
    const certificationTags = formData?.certificate?.length > 0 ? formData?.certificate?.map((i) => ({
      field: "certificate",
      value: i.value || i.inputValue,
      required: i.required,
    })) : []
    const teamsTags = formData?.teams?.length > 0 ? formData?.teams?.map((i) => ({
      field: "teams",
      value: i.value || i.inputValue,
      required: i.required,
    })) : []
    const tagsData = [...industryTags, ...softwareTags, ...skillTags, ...languageTags, ...certificationTags, ...teamsTags];

    const formatSoftTags = (data = []) => {
      return (
        "" +
        data.reduce((acc, i) => {
          if (!i.required) {
            return [...acc, i.value || i.inputValue];
          }
          return acc;
        }, [])
      );
    };

    const formatHardTags = (data = []) => {
      return (
        "" +
        data.reduce((acc, i) => {
          if (i.required) {
            return [...acc, i.value || i.inputValue];
          }
          return acc;
        }, [])
      );
    };

    const serviceLinesPlus = formatSoftTags(formData.serviceLinesPlusTags);
    const requiredServiceLinesPlus = formatHardTags(
      formData.serviceLinesPlusTags
    );

    const earningsMinBudget = formData.earningsMinBudget;
    const earningsMaxBudget = formData.earningsMaxBudget;
    const isEarningsBudgetValid =
      earningsMinBudget > 0 &&
      earningsMaxBudget > 0 &&
      earningsMinBudget <= earningsMaxBudget;
    const buildEarningsBudget = () => {
      return `$${earningsMinBudget / 2} - $${earningsMaxBudget / 2}`;
    };

    let estimatedTakeHome;
    if (process.env.REACT_APP_ESTIMATED_TAKE_HOME_ENABLED === "true") {
      estimatedTakeHome = isEarningsBudgetValid ? buildEarningsBudget() : null;
    } else {
      estimatedTakeHome = estimatedBudget;
    }

    /* TODO - if string of IDs instead of values
    
    const serviceLinesPlus =
      "" +
      serviceLinesPlusOptions
        .filter((slpOption) =>
          data.serviceLinesPlusTags.some(
            (slpTag) =>
              slpOption?.value === (slpTag.value || slpTag.inputValue) &&
              !slpTag.required
          )
        )
        .map(({ id }) => id);

    const requiredServiceLinesPlus =
      "" +
      serviceLinesPlusOptions
        .filter((slpOption) =>
          data.serviceLinesPlusTags.some(
            (slpTag) =>
              slpOption?.value === (slpTag.value || slpTag.inputValue) &&
              slpTag.required
          )
        )
        .map(({ id }) => id); */
    const requestData = {
      name: formData.name,
      primaryServiceLine: formData.serviceLine,
      description: formData.description,
      notes: formData.notes,
      projectSize: formData.projectSize,
      opportunityTypeMM: formData.opportunityTypeMM,
      pitchNotes: formData.pitchNotes,
      matchmakingQuery: {
        tags: tagsData,
        rateRange: {
          min: 0,
          max: parseInt(formData.clientRate),
        },
      },
      billRateType: formData.billRateType,
      frequency: formData.frequency,
      estimatedBudget: estimatedTakeHome,
      serviceLinesPlus,
      requiredServiceLinesPlus,
      estimatedStartDate: formData.estimatedStartDate,
      estimatedEndDate: formData.estimatedEndDate,
      pitchRequired: formData?.pitchRequired,
      questions: [
        ...formData.questions.map((q) => ({
          id: q.savedid,
          question: q.value,
        })),
        ...deletedQuestionIds.map((d) => ({ id: d, question: null })),
      ].filter(q => q?.question?.trim().length > 0)
    };
    const { data: responseData } = await updateProposal(
      defaultValues.id,
      requestData
    );

    setMatchQuery({ ...matchQuery, ...transformProposalData(responseData?.data?.updateProposal) });


    // error
    if (
      responseData.errors &&
      responseData.errors[0].extensions?.code === "400"
    ) {
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
      return;
    }

    await updateProposalFn();
  };
  const opportunityInformation =
    "Opportunity type is an exception criteria and should only be used by authorized users. Authorized users to meet special conditions. All exception conditions will be reported to management.";

  const serviceLinePlusInfo = `<ol style="list-style:disc;margin-left:10px;">
      <li>select the tag twice for must-have</li>
      <li>select the tag once for nice-to-have</li>
      <li>select the X to exclude from search</li>
    </ol>`;

  // const pitchRequirementInfo =
  //   "If a pitch is not required, let the experts know by checking the appropriate box";

  const isPilotForm = isPilotVersion(opportunityTypeMM);
  const isSlPlusEnabled = isServiceLinePlusEnabled(opportunityTypeMM);

  const checkQuestionValues = () => {
    const lastValue = watchFields.some(q => q?.value?.trim().length === 0)
    let isValid = true
    if (lastValue) {
      enqueueSnackbar(`Please fill all questions`, {
        variant: "error",
      });
      isValid = false
    }
    return isValid
  }

  const addQuestion = () => {
    append({ value: "" });
  };

  const onAppendQuestions = (i) => {
    append(i.questions.map((q) => ({ value: q })))
  }

  const handleApiResponse = async (response, callType) => {
    if (response?.status === 200) {
      enqueueSnackbar(response?.data?.data?.[callType]?.message ?? "Successfully updated!", {
        variant: "success",
      });
      const data = await getAllQuestionsTemplates(user?.userId)
      setTemplates(data?.data?.data?.getAllQuestionsTemplates)
      setSaveQuestionType("")
    } else {
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    }
  }

  const onDeleteQuestionTemplate = async (i) => {
    const userId = user?.userId;
    const payload = { templateId: i.value, userId }
    try {
      setIsLoading(true)
      const response = await deleteQuestionsTemplate(payload)
      handleApiResponse(response, 'deleteQuestionsTemplate')
      setIsLoading(false)
      resetDeleteTemplate()
    } catch (error) {
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
      setIsLoading(false)
    }
  }

  const onSaveQuestions = async () => {
    if (!templateName?.trim().length) {
      setTemplateError(true)
      return;
    }
    if (!checkQuestionValues()) {
      setTemplateError(false)
      return
    }
    setTemplateError(false)
    const userId = user?.userId
    const payload = {
      questions: watchFields?.map(field => field?.value),
      templateName: templateName,
      userId
    }
    try {
      setIsLoading(true)
      const response = await createQuestionsTemplate(payload)
      handleApiResponse(response, 'createQuestionsTemplate')
      setIsLoading(false)
      setTemplateName("")
      setTemplateError(false)
    } catch (error) {
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
      setIsLoading(false)
    }
  }

  const onUpdateQuestions = async () => {
    if (!selectedTemplatedIdForUpdate || !checkQuestionValues()) {
      setTemplateError(true)
      return;
    }
    if (!checkQuestionValues()) {
      setTemplateError(false)
      return
    }
    setTemplateError(false)
    const userId = user?.userId
    const payload = {
      questions: watchFields?.map(field => field?.value),
      templateId: selectedTemplatedIdForUpdate,
      userId
    }
    try {
      setIsLoading(true)
      const response = await updateQuestionsTemplate(payload)
      handleApiResponse(response, 'updateQuestionsTemplate')
      setIsLoading(false)
    } catch (error) {
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
      setIsLoading(false)
    }
  }

  const resetDeleteTemplate = () => {
    setSelectedQuestionTemplate(null)
    setShowConfirmationModal(false)
  }

  return (
    <div className="flex flex-row p-8 rounded-md w-full">
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autoComplete="off"
        className="w-3/4"
      >
        {
          <>
            <div className="grid grid-cols-1">
              <Heading size="h4">{defaultValues.client?.name}</Heading>
            </div>
            <div className="grid grid-cols-1">
              <Heading size="h5">Proposal Attributes</Heading>
            </div>
            <div className="grid grid-cols-3 grid-flow-col gap-y-2 gap-x-2 my-3">
              <Controller
                fullWidth
                name="opportunityTypeMM"
                rules={{ required: "Opportunity Type required" }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <FormLabel className="my-2" component="legend">
                      <div className="flex">
                        <Text size="md">Opportunity Type</Text>
                        <InfoButton information={opportunityInformation} />
                      </div>
                    </FormLabel>
                    <span id="mm-editProposal-opportunityType">
                      <SelectField
                        // {...field}
                        value={field.value}
                        onChange={(v) => {
                          // TODO
                          if (isSlPlusEnabled) {
                            setValue("serviceLine", "Tax Preparation");
                          }
                          field.onChange(v);
                        }}
                        options={Object.values(OPPORTUNITY_TYPES)}
                        error={error}
                        helperText={error ? error.message : null}
                      />
                    </span>
                  </>
                )}
                control={control}
              />
            </div>
            <div>
              <i>Information on this page is Expert facing. It populates Expert emails and the Find Opportunities page</i>
            </div>
            <Disclosure defaultOpen>
              {({ open }) => (
                <>
                  <Disclosure.Button className={`border mt-2 w-full  px-4 py-2 flex ${open ? "rounded-t bg-gray-100" : "rounded"}`}   >
                    <div className="w-full flex justify-between items-center">
                      <Heading size="h5">Proposal Information</Heading>
                      <ChevronRightRounded className={open ? 'rotate-90 transform' : ''} />

                    </div>
                  </Disclosure.Button>
                  <Disclosure.Panel className={"border p-4 rounded-b"}>
                    <div className="pt-2">
                      <Controller
                        name="name"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <FormLabel className="my-2" component="legend">
                              Proposal Title*
                            </FormLabel>
                            <TextField
                              fullWidth
                              variant="outlined"
                              {...field}
                              error={!!error}
                              helperText={error ? error.message : null}
                            />
                          </>
                        )}
                        rules={{ required: "Proposal Title required" }}
                      />
                    </div>
                    <div className="grid grid-cols-1 my-2">
                      <Controller
                        name="description"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <FormLabel className="my-2" component="legend">
                              Proposal Description*
                            </FormLabel>
                            <TextField
                              multiline
                              className={classes.textArea}
                              variant="outlined"
                              placeholder="Provide a Porposal Description"
                              {...field}
                              error={!!error}
                              helperText={error ? error.message : null}
                            />
                          </>
                        )}
                        rules={{ required: "Porposal Description required" }}
                      />
                    </div>
                    <div className="grid grid-cols-3 grid-flow-col gap-y-2 gap-x-2 my-3">
                      <div>
                        <Controller
                          name="serviceLine"
                          rules={{ required: "Service required" }}
                          render={({ field, fieldState: { error } }) => (
                            <>
                              <FormLabel className="my-2" component="legend">
                                Service Line*
                              </FormLabel>
                              <SelectService
                                // disabled={
                                //   isPilotForm
                                // }
                                fullWidth
                                {...field}
                                options={serviceOptions}
                                error={error}
                                helperText={error ? error.message : null}
                              />
                            </>
                          )}
                          control={control}
                        />
                      </div>
                      <div>
                        <Controller
                          fullWidth
                          name="billRateType"
                          rules={{ required: "Hours Billed required" }}
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <>
                              <FormLabel className="my-2" component="legend">
                                Billing Type(hours)*
                              </FormLabel>
                              <SelectField
                                value={billingValues[value]}
                                onChange={(v) => {
                                  const updatedRateType = getRateType(
                                    v,
                                    getValues("frequency")
                                  );
                                  setRateType(updatedRateType);
                                  onChange(v);
                                }}
                                error={error}
                                options={Object.values(billingValues)}
                              />
                            </>
                          )}
                          control={control}
                        />
                      </div>

                      <div>
                        <Controller
                          fullWidth
                          name="frequency"
                          rules={{ required: "Occurence required" }}
                          render={({ field, fieldState: { error } }) => (
                            <>
                              <FormLabel className="my-2" component="legend">
                                Project Type(occurrence)*
                              </FormLabel>
                              <SelectField
                                {...field}
                                onChange={(v) => {
                                  const updatedRateType = getRateType(
                                    getValues("billRateType"),
                                    v
                                  );
                                  setRateType(updatedRateType);
                                  setEffortType(getEffortType(v))
                                  if (!defaultValues?.estimatedEndDate) {
                                    setValue(
                                      "estimatedEndDate",
                                      new Date(
                                        watchFrequency === "Recurring Monthly"
                                          ? new Date().getTime() +
                                          365 * 24 * 60 * 60 * 1000
                                          : watchStartDateField
                                            ? new Date(watchStartDateField).getTime() +
                                            30 * 24 * 60 * 60 * 1000
                                            : new Date().getTime() +
                                            30 * 24 * 60 * 60 * 1000
                                      )
                                        .toISOString()
                                        .split("T")[0]
                                    );
                                  }
                                  field.onChange(v);
                                }}
                                error={error}
                                options={Object.values(occurenceValues)}
                              />
                            </>
                          )}
                          control={control}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 grid-flow-col gap-y-2 gap-x-2 my-6">
                      <div>
                        <Controller
                          fullWidth
                          name="projectSize"
                          rules={{ required: "Effort required" }}
                          render={({ field, fieldState: { error } }) => (
                            <>
                              <FormLabel className="my-2" component="legend">
                                Est. Hours(effort) <span className="font-bold text-md text-black">
                                  {effortType}
                                </span> *
                              </FormLabel>
                              <SelectEffort
                                {...field}
                                onChange={(v) => {
                                  setEstimatedBudget(
                                    getEstimatedBudget(v, getValues("clientRate"))
                                  );
                                  field.onChange(v);
                                }}
                                options={effortOptions}
                                error={error}
                                helperText={error ? error.message : null}
                              />
                            </>
                          )}
                          control={control}
                        />
                      </div>

                      <div>
                        <Controller
                          name="clientRate"
                          control={control}
                          defaultValue=""
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <>
                              <FormLabel className="my-2" component="legend">
                                Client Est. Rate*
                              </FormLabel>
                              <TextField
                                fullWidth
                                id="clientRate"
                                type="number"
                                variant="outlined"
                                InputProps={{
                                  inputProps: {
                                    min: 0,
                                  },
                                }}
                                value={value}
                                onChange={(v) => {
                                  setEstimatedBudget(
                                    getEstimatedBudget(getValues("projectSize"), v)
                                  );
                                  onChange(v);
                                }}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            </>
                          )}
                          rules={{ required: "Client Rate required" }}
                        />
                      </div>
                      {process.env.REACT_APP_ESTIMATED_TAKE_HOME_ENABLED === "true" ? (
                        <div className="flex flex-row flex-wrap">
                          <div className="grid grid-cols-7 grid-flow-col">
                            <div className="col-span-3">
                              <Controller
                                name="earningsMinBudget"
                                defaultValue=""
                                control={control}
                                rules={{
                                  required: "Min Budget Required",
                                  validate: (value) => {
                                    const maxBudget = getValues("earningsMaxBudget");
                                    if (value <= 0) {
                                      return "min budget should be greater than 0";
                                    }

                                    if (maxBudget && Number(value) > Number(maxBudget)) {
                                      return "The min budget should be lesser than max budget";
                                    }
                                    return true;
                                  },
                                }}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <>
                                    <FormLabel
                                      className="my-2"
                                      component="legend"
                                      style={{
                                        whiteSpace: "nowrap",
                                        overflow: "visible",
                                      }}
                                    >
                                      Estimated Client Budget (Client Rate{" "}
                                      <span className="font-bold text-md text-black">
                                        {rateType}
                                      </span>
                                      )*
                                    </FormLabel>
                                    <TextField
                                      placeholder="min budget"
                                      fullWidth
                                      id="earningsMinBudget"
                                      type="number"
                                      variant="outlined"
                                      value={value}
                                      onChange={(v) => {
                                        onChange(v);
                                        trigger('earningsMaxBudget')
                                      }}
                                      error={!!error}
                                      helperText={error ? error.message : null}
                                    />
                                  </>
                                )}
                              />
                            </div>
                            <div
                              className="col-span-1 flex justify-center text-4xl"
                              style={{ marginTop: 32 }}
                            >
                              -
                            </div>
                            <div className="col-span-3" style={{ marginTop: 32 }}>
                              <Controller
                                name="earningsMaxBudget"
                                defaultValue=""
                                control={control}
                                rules={{
                                  required: "Max Budget Required",
                                  validate: (value) => {
                                    const minBudget = getValues("earningsMinBudget");
                                    if (value <= 0) {
                                      return "max budget should be greater than 0";
                                    }
                                    if (minBudget && Number(value) < Number(minBudget)) {
                                      return "The max budget should be greater than min budget";
                                    }
                                    return true;
                                  },
                                }}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <>
                                    <TextField
                                      placeholder="max budget"
                                      fullWidth
                                      id="earningsMaxBudget"
                                      type="number"
                                      variant="outlined"
                                      value={value}
                                      onChange={(v) => {
                                        onChange(v);
                                        trigger('earningsMinBudget')
                                      }}
                                      error={!!error}
                                      helperText={error ? error.message : null}
                                    />
                                  </>
                                )}
                              />
                            </div>
                          </div>
                          {showExpertTakeHome && (
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "visible",
                                marginTop: "8px",
                              }}
                            >
                              Est. Take-home (Expert Rate):{" "}
                              <span className="font-bold text-sm text-black">
                                {`$${earningsMinBudget / 2}-${earningsMaxBudget / 2}`}
                                {rateType}
                              </span>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div>
                          <FormLabel className="my-2" component="legend">
                            Est. Budget
                          </FormLabel>
                          <TextField
                            disabled
                            fullWidth
                            variant="outlined"
                            value={estimatedBudget}
                          />
                        </div>
                      )}
                    </div>
                    <div className="grid grid-cols-3 grid-flow-col gap-y-2 gap-x-2">
                      <div>
                        <Controller
                          name="estimatedStartDate"
                          fullWidth
                          rules={{ required: "Estimated Start Date required" }}
                          render={({ field, fieldState: { error } }) => (
                            <>
                              <FormLabel className="my-2" component="legend">
                                Est. Start Date*
                              </FormLabel>
                              <TextField
                                {...field}
                                fullWidth
                                id="estimatedStartDate"
                                type="date"
                                variant="outlined"
                                {...register("estimatedStartDate", {
                                  required: true,
                                })}
                                InputProps={{
                                  inputProps: {
                                    min: new Date(
                                      new Date().getTime() + 3 * 24 * 60 * 60 * 1000
                                    )
                                      .toISOString()
                                      .split("T")[0],
                                    max: new Date(
                                      new Date().getTime() + 365 * 24 * 60 * 60 * 1000
                                    )
                                      .toISOString()
                                      .split("T")[0],
                                  },
                                }}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            </>
                          )}
                          control={control}
                        />
                      </div>
                      <div>
                        <Controller
                          name="estimatedEndDate"
                          key={watchStartDateField}
                          fullWidth
                          rules={{ required: "Estimated End Date required" }}
                          render={({ field, fieldState: { error } }) => (
                            <>
                              <FormLabel className="my-2" component="legend">
                                Est. End Date
                              </FormLabel>
                              <TextField
                                {...field}
                                fullWidth
                                id="estimatedEndDate"
                                type="date"
                                variant="outlined"
                                {...register("estimatedEndDate", {
                                  required: true,
                                  validate: (estimatedEndDate) =>
                                    estimatedEndDate >= watchStartDateField,
                                })}
                                InputProps={{
                                  inputProps: {
                                    min:
                                      watchStartDateField ||
                                      new Date(
                                        new Date().getTime() + 3 * 24 * 60 * 60 * 1000
                                      )
                                        .toISOString()
                                        .split("T")[0],
                                    max: new Date(
                                      new Date().getTime() + 365 * 24 * 60 * 60 * 1000
                                    )
                                      .toISOString()
                                      .split("T")[0],
                                  },
                                }}
                                error={!!error || field.value < watchStartDateField}
                                helperText={
                                  field.value < watchStartDateField
                                    ? "Estimated End Date should be after Estimated Start Date"
                                    : error
                                      ? error.message
                                      : null
                                }
                              />
                            </>
                          )}
                          control={control}
                        />
                      </div>
                    </div>
                  </Disclosure.Panel>
                </>
              )}

            </Disclosure>

            <Disclosure defaultOpen>
              {({ open }) => (
                <>

                  <Disclosure.Button className={`border mt-2 w-full px-4 py-2 flex ${open ? "rounded-t bg-gray-100" : "rounded"}`}   >
                    <div className="w-full flex justify-between items-center">
                      <Heading size="h5">Additional Proposal Context</Heading>
                      <ChevronRightRounded className={open ? 'rotate-90 transform' : ''} />
                    </div>
                  </Disclosure.Button>
                  <Disclosure.Panel className={"border p-4 rounded-b"}>
                    <div className="grid grid-cols-1">
                      <Heading size="h6" className="font-semibold">Call Notes</Heading>
                    </div>
                    <div className="grid grid-cols-1">
                      <Controller
                        control={control}
                        name="notes"
                        value={notes}
                        render={({ field: { name }, fieldState: { error } }) => {
                          return (
                            <span id="mm-editProposal-notes">
                              <ReactQuillEditor
                                {...register("notes")}
                                value={notes}
                                error={error?.message ?? ""}
                                onChange={(e) => {
                                  setNotes(e);
                                  setValue(name, e);
                                }}
                                placeholder="Add a Note"
                              />
                            </span>
                          );
                        }}
                      />
                    </div>
                    <Heading size="h6" className="mb-2 mt-2">
                      Expert Response
                    </Heading>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 place-items-between w-full ">
                      <Controller
                        name="pitchRequired"
                        control={control}
                        defaultValue={1}
                        render={({ field: { onChange, value } }) => (
                          <ToggleButtonGroup
                            value={value}
                            exclusive
                            onChange={(_, newValue) => {
                              if (newValue) {
                                onChange(newValue === "pitch")
                              }
                            }}
                            defaultValue={defaultValues?.pitchRequired}
                            size="small"
                            aria-label="Pitch"
                          >
                            <ToggleButton
                              value={"pitch"}
                              aria-label="left aligned"
                              className={`${watchPitchRequired ? "bg-green" : "bg-transparent"
                                }`}
                              style={{
                                color: colors.paroBlack,
                                opacity: watchPitchRequired ? 1 : 0.7,
                                paddingLeft: "16px",
                                paddingRight: "16px",
                              }}
                            >
                              Pitch
                            </ToggleButton>
                            <ToggleButton
                              value={"questions"}
                              aria-label="centered"
                              className={`${!watchPitchRequired ? "bg-green" : "bg-transparent"
                                }`}
                              style={{
                                color: colors.paroBlack,
                                opacity: !watchPitchRequired ? 1 : 0.7,
                                paddingLeft: "16px",
                                paddingRight: "16px",
                              }}
                            >
                              Questions
                            </ToggleButton>
                          </ToggleButtonGroup>
                        )}
                      />
                      {!watchPitchRequired && (
                        <MMSelect
                          value={saveQuestionType}
                          disabled={questionFields.length === 0 || isLoading}
                          handleChange={(_, i) => {
                            setSaveQuestionType(i.value);
                          }}
                          label={"Save Questions"}
                          options={SAVE_DEFAULT_OPTIONS}
                        />
                      )}
                    </div>
                    {watchPitchRequired ? (
                      <>
                        <div className="grid grid-cols-1 mt-2">
                          <Heading size="h6">Pitch Notes</Heading>
                        </div>
                        <div className="grid grid-cols-1">
                          <Controller
                            control={control}
                            name="pitchNotes"
                            render={({
                              field: { name, value },
                              fieldState: { error },
                            }) => {
                              return (
                                <span id="mm-editProposal-pitch-notes">
                                  <ReactQuillEditor
                                    value={value}
                                    error={error?.message ?? ""}
                                    onChange={(e) => {
                                      setValue(name, e);
                                    }}
                                    placeholder="Add recommendations that can help experts when writing their pitch"
                                  />
                                </span>
                              );
                            }}
                          />
                        </div>
                      </>
                    ) : (
                      <div>
                        <div className="grid grid-cols-1 my-2">
                          <Text size="sm">
                            Experts are required to answer all questions.{" "}
                            {watchFields?.length === 0 && (
                              <span style={{ color: colors.paroRed }}>
                                Add at at least 1 question.
                              </span>
                            )}
                          </Text>
                        </div>
                        {saveQuestionType === "new" && (
                          <div className="grid grid-cols-1 border p-4  rounded-md mt-4">
                            <div className="flex items-center justify-between gap-2">
                              <div className="w-full">
                                <Input
                                  label="Question Template Name *"
                                  type="text"
                                  placeholder="Template Name"
                                  isInvalid={templateError}
                                  value={templateName}
                                  onChange={(e) => setTemplateName(e.target.value)}
                                  isInvalidText="Please enter a template name"
                                  maxLength={50}
                                  required
                                  className="font-medium"
                                  disabled={isLoading}
                                />
                              </div>
                              <div className={`flex gap-2 ${!templateError && "mt-4"}`}>
                                <Button
                                  type="button"
                                  icon={<IconX />}
                                  size={"md"}
                                  color="danger"
                                  disabled={isLoading}
                                  className="mt-1 !border-0	"
                                  onClick={() => setSaveQuestionType("")}
                                />
                                <Button
                                  type="button"
                                  icon={<IconCheck />}
                                  onClick={onSaveQuestions}
                                  size={"md"}
                                  disabled={isLoading}
                                  isLoading={isLoading}
                                  color="success"
                                  className="mt-1"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        {saveQuestionType === "existing" && (
                          <div className="grid grid-cols-1 border p-4  rounded-md mt-4">
                            <div className="flex items-center justify-between gap-2">
                              <div className="w-full">
                                <Text size="sm" className="mb-2 font-bold">
                                  Save to existing template *
                                </Text>
                                <MMSelect
                                  value={selectedTemplatedIdForUpdate}
                                  disabled={isLoading}
                                  handleChange={(_, i) => {
                                    setSelectedTemplateIdForUpdate(i.value);
                                  }}
                                  label={"Select Existing Template"}
                                  options={templates?.map((template) => ({
                                    label: template.templateName,
                                    value: template.templateId,
                                    ...template,
                                  }))}
                                />
                              </div>
                              <div className="flex gap-2 pt-8 mt-0">
                                <Button
                                  type="button"
                                  icon={<IconX />}
                                  size={"md"}
                                  color="danger"
                                  disabled={isLoading}
                                  className="mt-1 !border-0	"
                                  onClick={() => setSaveQuestionType("")}
                                />
                                <Button
                                  type="button"
                                  icon={<IconCheck />}
                                  onClick={onUpdateQuestions}
                                  size={"md"}
                                  disabled={isLoading || !selectedTemplatedIdForUpdate}
                                  isLoading={isLoading}
                                  color="success"
                                  className="mt-1"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        {/* {defaultValues.questions?.map((q, i) => (
                  <div key={i} className="py-2">
                    <>
                      <FormLabel className="my-2" component="legend">
                        {`Question ${i + 1}.`}
                      </FormLabel>
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={q}
                        disabled={true}
                      />
                    </>
                  </div>
                ))} */}
                        {questionFields.map((questionField, i) => {
                          return (
                            <div
                              key={questionField.id} // important to include key with field's id
                              className="py-2"
                            >
                              <Controller
                                name={`questions.${i}.value`}
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                  <div className="flex border items-center p-3 w-full rounded-md gap-4">
                                    <FormLabel
                                      className="my-2"
                                      style={{ color: "black", fontWeight: "bold" }}
                                      component="legend"
                                    >
                                      {`Q${i + 1}:`}
                                    </FormLabel>
                                    <div
                                      className="flex items-center gap-4"
                                      style={{ width: "100%" }}
                                    >
                                      <div className="w-full">
                                        <Input
                                          disabled={
                                            defaultValues.proposalFreelancers?.some(
                                              (p) =>
                                                p.stage >=
                                                StageOptionsMap.get("Proposed")
                                            ) && !!questionField.savedid
                                          }
                                          {...field}
                                          required
                                          label=""
                                          type="text"
                                          placeholder="Enter Question"
                                          isInvalid={questionErrors?.some((qIdx) => i === qIdx)}
                                          isInvalidText={error ? error.message : null}
                                          className="font-medium"
                                        />
                                      </div>
                                      {questionFields.length > 1 ? (
                                        <Button
                                          type="button"
                                          icon={<IconTrash />}
                                          disabled={
                                            defaultValues.proposalFreelancers?.some(
                                              (p) =>
                                                p.stage >=
                                                StageOptionsMap.get("Proposed")
                                            ) && !!questionField.savedid
                                          }
                                          onClick={() => {
                                            setDeletedQuestionIds(
                                              (deletedQuestionIds) => [
                                                ...deletedQuestionIds,
                                                questionField.savedid,
                                              ]
                                            );
                                            remove(i);
                                          }}
                                          size={"md"}
                                          className="mt-1"
                                        />
                                      ) : null}
                                    </div>
                                  </div>
                                )}
                              />
                            </div>
                          );
                        })}


                        <div className="flex items-center gap-2 mt-2 flex-wrap">
                          <Button
                            label="Add Question"
                            type="button"
                            onClick={addQuestion}
                            size={"md"}
                            iconLeft={<IconPlus />}
                          ></Button>
                          {!watchPitchRequired && (
                            <MMSelect
                              value={selectedTemplatedId}
                              showConfirmation={showConfirmationModal}
                              onYes={() => onDeleteQuestionTemplate(selectedQuestionTemplate)}
                              onNo={resetDeleteTemplate}
                              showConfirmationMsg={`Are you sure you want to delete
                        ${selectedQuestionTemplate?.label ?? ""}?`}
                              disabled={isLoading}
                              icon={<IconTrash size="small" />}
                              handleChange={(_, i) => {
                                setSelectedTemplateId(i.value);
                                onAppendQuestions(i);
                              }}
                              handleIconClick={(_, i) => {
                                setSelectedQuestionTemplate(i);
                                setShowConfirmationModal(true);
                              }}
                              isLoading={isLoading}
                              label={"Add From template"}
                              options={templates?.map((template) => ({
                                label: template.templateName,
                                value: template.templateId,
                                ...template,
                              }))}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>

            <Disclosure defaultOpen>
              {({ open }) => (
                <>

                  <Disclosure.Button className={`border mt-2 w-full  px-4 py-2 flex ${open ? "rounded-t bg-gray-100" : "rounded"}`}   >
                    <div className="w-full flex justify-between items-center">
                      <Heading size="h5">Find Opportunity Requirements</Heading>
                      <ChevronRightRounded className={open ? 'rotate-90 transform' : ''} />
                    </div>
                  </Disclosure.Button>
                  <Disclosure.Panel className={"border p-4 rounded-b"}>
                    <div className="grid grid-cols-2 grid-flow-col gap-y-2 gap-x-2">
                      <div>
                        <Controller
                          name="industries"
                          fullWidth
                          rules={{ required: "Industry required" }}
                          render={({ field, fieldState: { error } }) => (
                            <>
                              <FormLabel className="my-2" component="legend">
                                Industry*
                              </FormLabel>
                              <span id="mm-editProposal-industry">
                                <SelectIndustry
                                  {...field}
                                  options={industryOptions}
                                  defaultOptions={
                                    defaultValues.client?.firmographics
                                      ?.paroEquivalentClearbitIndustries?.length > 0
                                      ? defaultValues.client?.firmographics?.paroEquivalentClearbitIndustries.map(
                                        (i) => ({
                                          field: "industries",
                                          value: i,
                                          required: false,
                                        })
                                      )
                                      : []
                                  }
                                  error={error}
                                  helperText={error ? error.message : null}
                                />
                              </span>
                            </>
                          )}
                          control={control}
                        />
                      </div>
                      <div>
                        <Controller
                          name="softwares"
                          fullWidth
                          rules={{ required: "Software required" }}
                          render={({ field, fieldState: { error } }) => (
                            <>
                              <FormLabel className="my-2" component="legend">
                                Software*
                              </FormLabel>
                              <span id="mm-editProposal-software">
                                <SelectSoftware
                                  {...field}
                                  options={softwareOptions}
                                  error={error}
                                  helperText={error ? error.message : null}
                                />
                              </span>
                            </>
                          )}
                          control={control}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-1 my-4">
                      <Controller
                        name="skills"
                        fullWidth
                        render={({ field }) => (
                          <>
                            <FormLabel className="my-2" component="legend">
                              Skills
                            </FormLabel>
                            <span id="mm-editProposal-skill">
                              <SelectSkills {...field} options={skillOptions} />
                            </span>
                          </>
                        )}
                        control={control}
                      />
                    </div>
                    <div className="grid grid-cols-1 my-4">
                      <Controller
                        name="language"
                        fullWidth
                        render={({ field }) => (
                          <>
                            <FormLabel className="my-2" component="legend">
                              Languages
                            </FormLabel>
                            <span id="mm-editProposal-language">
                              <SelectLangOrCert
                                {...field}
                                options={languageOptions}
                                placeholder="Languages"
                              />
                            </span>
                          </>
                        )}
                        control={control}
                      />
                    </div>
                    <div className="grid grid-cols-1 my-4">
                      <Controller
                        name="certificate"
                        fullWidth
                        render={({ field }) => (
                          <>
                            <FormLabel className="my-2" component="legend">
                              Certifications
                            </FormLabel>
                            <span id="mm-editProposal-certificate">
                              <SelectLangOrCert
                                {...field}
                                options={certificationsOptions}
                                placeholder="Certifications"
                              />
                            </span>
                          </>
                        )}
                        control={control}
                      />
                    </div>
                    <div className="grid grid-cols-2 grid-flow-col gap-y-2 gap-x-2">
                      <div>
                        <Controller
                          name="teams"
                          fullWidth
                          render={({ field }) => (
                            <>
                              <FormLabel className="my-2" component="legend">
                                Teams
                              </FormLabel>
                              <span id="mm-editProposal-teams">
                                <SelectLangOrCert
                                  {...field}
                                  options={teamsOptions}
                                  placeholder="Teams"
                                />
                              </span>
                            </>
                          )}
                          control={control}
                        />
                      </div>
                    </div>

                    {isSlPlusEnabled && (
                      <>
                        <div className="grid grid-cols-1 mt-4">
                          <Heading size="h5">
                            {opportunityTypeMM ===
                              OPPORTUNITY_TYPES.CrossSellNewExpertNewService
                              ? "Service Line Plus"
                              : "Service Line Plus*"}
                            <div className=" inline-block">
                              <InfoButton information={serviceLinePlusInfo} />
                            </div>
                          </Heading>
                        </div>
                        <div className="grid grid-cols-1 ">
                          <Controller
                            name="serviceLinesPlusTags"
                            fullWidth
                            {...register("serviceLinesPlusTags", {
                              required: isPilotForm
                                ? "Service Line Plus required"
                                : false,
                            })}
                            render={({ field, fieldState: { error } }) => (
                              <>
                                <FormLabel className="my-2" component="legend">
                                  <Text size="sm">
                                    Included Service Line Plus (SL+) Capabilities
                                  </Text>
                                </FormLabel>
                                <span id="mm-editProposal-serviceLinesPlusTags">
                                  <SelectServiceLinesPlus
                                    {...field}
                                    options={serviceLinesPlusOptions.map(
                                      (slp) => slp.value
                                    )}
                                    error={error}
                                    helperText={error ? error.message : null}
                                  />
                                </span>
                              </>
                            )}
                            control={control}
                          />
                        </div>
                      </>
                    )}

                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>

            <div className="grid grid-cols-4 my-3">
              <span id="mm-editProposal-save">
                <Button
                  isLoading={formState.isSubmitting}
                  label={`Save`}
                  type="submit"
                  disabled={isLoading}
                  color="success"
                />
              </span>
            </div>
          </>
        }
      </form>
      <div className="w-1/4 pl-8 grid gap-4 items-start">
        <div>
          <Gpt3Component
            defaultValues={defaultValues}
            notes={notes}
            setValue={setValue}
          />
        </div>
        <div className="bg-green mt-4 p-2">
          <div>
            <div className="flex mt-2">
              <IconCalendar />
            </div>
            <Heading size="h5">Estimated start date</Heading>
            <Text size="md">
              On average, projects begin 3-5 business days after the client
              accepts the proposal pitch.
            </Text>
          </div>
          <div>
            <div className="flex mt-2">
              <IconCalendar />
            </div>
            <Heading size="h5">Estimated end date</Heading>
            <Text size="md">
              Estimated end date is pre-determined based on project frequency.
              It can be edited as per the proposal requirements.
            </Text>
          </div>
          {isSlPlusEnabled && (
            <div>
              <div className="flex mt-2">
                <IconCalendar />
              </div>
              <Heading size="h5">Service Line Plus (SL+)</Heading>
              <Text>
                An AI-driven search enhancement designed to drive the proposal
                search by providing deeper and higher accuacy matches based on
                the expert’s workload and unbooked availability.
              </Text>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
