import { useState } from "react";
import ConfirmationModal from "./ConfirmationModal";
import { stopRenewal } from "../services/apiService";
import { useSnackbar } from "notistack";
import { IconDocumentClock, IconDocumentText } from "@paro.io/base-icons";
import { Button as BaseUiButton, Select } from "@paro.io/base-ui";
import { Auth0Context } from "../contexts/Auth0Context";
import { useContext } from "react";
import {
  OPPORTUNITY_TYPES,
  ORIGINAL_OPPORTUNITY_TYPES,
} from "../data/appConstants";
import { reasonTypeOptions } from "../utils/reasonCodesConstants";
import { useLocation, useHistory } from "react-router-dom";
import dayjs from "dayjs";

const RenewalCardItem = ({ proposal, refetchProposals, showModal }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(Auth0Context);
  const [showRenewalModal, setShowRenewalModal] = useState(showModal ?? false);
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const handleShowModal = (val) => {
    setShowRenewalModal(val);
    if (showModal) {
      history.replace(location.pathname);
    }
  };

  const handleSubmit = async () => {
    //we need to add mutation for adding the reason to the table.
    setLoading(true);
    try {
      const payload = {
        renewalProposalId: proposal.renewalProposalId,
        rejectedReason: reason,
        rejectedByUserId: user.auth0UserId,
      };
      await stopRenewal(payload);
      handleShowModal(false);
      setReason("");
      enqueueSnackbar(`Proposal Renewal Stopped!`, {
        variant: "success",
      });
      refetchProposals();
      setLoading(false);
    } catch (err) {
      enqueueSnackbar(`Errror: ${err}`, {
        variant: "error",
      });
    }
  };

  const renewalStartDate =
    proposal?.frequency === "Recurring monthly" &&
    proposal?.billRateType === "HOURLY"
      ? dayjs(proposal?.projectEndDate).add(1, "day").format("YYYY-MM-DD")
      : dayjs(proposal?.projectEndDate)
          .add(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
  const diffStartEnd = dayjs(proposal?.projectEndDate).diff(
    dayjs(proposal?.projectStartDate),
    "day"
  );
  const renewalEndDate = dayjs(renewalStartDate)
    .add(diffStartEnd, "day")
    .format("YYYY-MM-DD");

  const aeWindowCloseDate = dayjs(proposal.aeEmailSentAt)
    .add(6, "day")
    .format("YYYY-MM-DD");

  return (
    <>
      <div className="relative flex flex-row">
        <div className="w-full h-full rounded bg-white flex flex-col">
          <div className="flex justify-end pr-10">
            <div className="font-semibold text-sm text-gray-600 z-20 mt-2 flex">
              {!!proposal.opportunityTypeMM
                ? proposal.opportunityTypeMM
                : Object.values(ORIGINAL_OPPORTUNITY_TYPES).includes(
                    proposal.opportunityType
                  )
                ? proposal.opportunityType
                : OPPORTUNITY_TYPES.NewBusiness}
            </div>
          </div>
          <div className="px-8 flex flex-col lg:block lg:text-left">
            <div className="flex justify-between">
              <div className="flex items-center mt-2 font-medium text-secondary">
                <IconDocumentText />
                <div className="ml-1">Proposal: {proposal.id}</div>
              </div>
            </div>
            <div className="flex items-center mt-2 font-medium text-secondary">
              <IconDocumentClock />
              <div className="ml-1">Proposal: {proposal.name}</div>
            </div>
            <div className="flex flex-col mt-6 font-medium text-secondary">
              <span className="font-bold text-black">
                Current Project Dates:
              </span>
              <span className="mt-2">
                {dayjs(proposal.projectStartDate).format("MMMM D, YYYY")} -{" "}
                {dayjs(proposal.projectEndDate).format("MMMM D, YYYY")}
              </span>
            </div>
            <div className="flex flex-col mt-2 font-medium">
              <span className="font-bold text-blue-700">
                Renewal Project Dates:
              </span>
              <span className="mt-2 text-secondary">
                {dayjs(renewalStartDate).format("MMMM D, YYYY")} -{" "}
                {dayjs(renewalEndDate).format("MMMM D, YYYY")}
              </span>
            </div>
          </div>
          <div className="p-8 flex flex-col justify-center mt-auto">
            <div className="py-4 text-center">
              <span className="text-red-500 font-bold">
                {proposal.rejectedByUserId
                  ? `The auto-renewal has been stopped`
                  : `Cancel the auto-renewal by ${dayjs(
                      aeWindowCloseDate
                    ).format("MMMM D, YYYY")}`}
              </span>
            </div>
            <BaseUiButton
              onClick={() => handleShowModal(true)}
              type="button"
              label="stop renewal"
              disabled={proposal.rejectedByUserId}
            />
          </div>
        </div>
      </div>
      {showRenewalModal && (
        <ConfirmationModal
          show={showRenewalModal}
          setShow={handleShowModal}
          title="Stop Auto Renewal"
          body={
            <div className="relative">
              <div
                className={`${
                  proposal.rejectedByUserId ? "pointer-events-none blur-sm" : ""
                }`}
              >
                <div className="flex-auto pb-3">
                  <p className="text-md pb-3">
                    Are you sure you want to stop the auto renewal for this
                    project? You cannot restart the auto-renewal process.
                  </p>
                  <Select
                    options={reasonTypeOptions}
                    value={reason}
                    required={true}
                    onChange={(e) => setReason(e.target.value)}
                    label="Reason Type"
                    className="mb-5"
                  />
                </div>
                <div className="flex-auto pt-3">
                  <BaseUiButton
                    label="yes, stop renewal"
                    onClick={handleSubmit}
                    color="danger"
                    disabled={!reason}
                    className="w-full"
                    isLoading={loading}
                  />
                </div>
              </div>
              {proposal.rejectedByUserId && (
                <div className="absolute inset-0 flex items-center justify-center z-10 bg-white bg-opacity-90">
                  <p className="text-lg font-bold text-gray-700">
                    This renewal already has been stopped.
                  </p>
                </div>
              )}
            </div>
          }
        />
      )}
    </>
  );
};

export default RenewalCardItem;
