import { Switch, Redirect } from "react-router-dom";
import ProposalsList from "./pages/ProposalsList";
// import ProposalViewer from "./pages/ProposalViewer";
import Checkout from "./pages/Checkout";
import { AuthenticatedRoute } from "./components/AuthenticatedRoute";
import { IS_PROD_ENVIRONMENT } from "./data/appConstants";
import { useEffect } from "react";
import FindFreelancers from "./pages/FindFreelancers";
import FreelancerView from "./pages/FreelancerView";
import FindFreelancersLegacy from "./pages/FindFreelancersLegacy";
import FindFreelancersUM from "./pages/FindFreelancersUM";

function App() {
  useEffect(() => {
    if (!IS_PROD_ENVIRONMENT) {
      console.log("This is not a prod environment");
    }
  }, []);
  const findFreelancer = process.env.REACT_APP_FREELANCER_ALGOLIA_SEARCH_ENABLED === "true"
  // const findFreelancerUM = process.env.REACT_APP_UNIFIED_MATCHING_ENABLED === "true"

  return (
    <div className="App">
      <Switch>
        <AuthenticatedRoute exact path="/" component={ProposalsList} />
        <AuthenticatedRoute
          exact
          path="/proposal/:proposalId"
          component={FindFreelancersUM}
        />
        <AuthenticatedRoute
          exact
          path="/find-a-freelancer/:proposalId"
          component={findFreelancer ? FindFreelancers : FindFreelancersLegacy}
        />
        {findFreelancer && (<AuthenticatedRoute
          exact
          path="/find-a-freelancer-legacy/:proposalId"
          component={FindFreelancersLegacy}
        />)}
        {/* {
          findFreelancerUM && (<AuthenticatedRoute
            exact
            path="/proposal-um/:proposalId"
            component={FindFreelancersUM}
          />)
        } */}
        <AuthenticatedRoute
          exact
          path="/proposal/:proposalId/checkout"
          component={Checkout}
        />
        <AuthenticatedRoute
          exact
          path="/freelancer-view/:freelancerId"
          component={FreelancerView}
        />
        {/* Route Not Found */}
        <Redirect from="*" to="/" />
      </Switch>
    </div>
  );
}

export default App;
