import { useCallback, useEffect, useState } from 'react';
import { getProposalFreelancers, getProposals } from '../services/apiService';
import CustomSpinner from './CustomSpinner';
import { Grid } from '@material-ui/core';
import { Heading } from '@paro.io/base-ui';
import ProposalCards from './ProposalCards';
import Pagination from '../components/Pagination';

export const ProposalTabContent = ({
  isDealDeskUser,
  currentSalesRep,
  setLoadingError,
  dataLoaded,
  setDataLoaded,
  proposalData,
  setProposalData,
  freelancersData,
  setFreelancersData,
  currentPage,
  setCurrentPage
}) => {
  const [loading, setLoading] = useState(false);
  const [proposalsPerPage, setProposalsPerPage] = useState(20);
  const [currentProposals, setCurrentProposals] = useState([]);

  const fetchFreelancersData = async (proposals) => {
    const freelancersData = {};
    await Promise.all(
      proposals.map(async (proposal) => {
        const response = await getProposalFreelancers(proposal.id);
        freelancersData[proposal.id] = response.data.data.getProposalFreelancers || [];
      }),
    );
    return freelancersData;
  };

  const refreshProposals = useCallback(async () => {
    setLoading(true);
      // Get current proposals
    const indexOfLastProposal = currentPage * proposalsPerPage;
    const indexOfFirstProposal = indexOfLastProposal - proposalsPerPage;

    try {
      const activeProposalData = await getProposals(currentSalesRep.value);
      const proposals = activeProposalData.data.data.getProposals;
      setProposalData(proposals);
      const currentProposals = proposals.slice(indexOfFirstProposal, indexOfLastProposal);
      const freelancers = await fetchFreelancersData(currentProposals);
      setCurrentProposals(currentProposals);
      setFreelancersData(freelancers);
      setLoading(false);
      setDataLoaded(true);
    } catch (err) {
      setLoadingError(true);
    }
  }, [currentSalesRep, setDataLoaded, setLoadingError, setFreelancersData, setProposalData, currentPage, proposalsPerPage]);

  useEffect(() => {
    if (!dataLoaded) {
      refreshProposals();
    }
  }, [currentSalesRep, dataLoaded, refreshProposals, currentPage]);

  if (loading || !dataLoaded) {
    return <CustomSpinner>Fetching Proposals</CustomSpinner>;
  }

  return (
    <div className="px-8 md:px-8 lg:px-16">
      {proposalData.length === 0 ? (
        <div className="flex justify-center p-8 mt-3 font-bold text-secondary">No Active Proposals</div>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <Heading size="h3">Select a Proposal to match Freelancers</Heading>
          </Grid>

          <ProposalCards
            data={currentProposals}
            refreshProposals={refreshProposals}
            isDealDeskUser={isDealDeskUser}
            freelancersData={freelancersData}
          />
        </Grid>
      )}
      {(currentProposals?.length >= 10 || currentPage > 1) && (
      <Pagination
        total={proposalData.length}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageItems={proposalsPerPage}
        setPerPageItems={setProposalsPerPage}
        setDataLoaded={setDataLoaded}
      />)}
    </div>
  );
};
