import React, { useContext, useState } from "react";
import TopSection from "../components/TopSection";
import { useEffect } from "react";
import { Grid, Box, Tabs, Tab, Typography } from "@material-ui/core";
import { CartContext } from "../components/CartContext";
import { useAuth0 } from "../contexts/Auth0Context";
import { Heap } from "@paro.io/react-heap";
import Select from "react-select";
import PropTypes from "prop-types";
import ParoError from "../components/ParoError";
import { useGetSalesRepsQuery } from "../apolloClient/hooks/queries";
import { useLocation } from "react-router-dom";
import { ProposalTabContent } from "../components/ProposalsTabContent";
import { RenewalsTabContent } from "../components/RenewalsTabContent";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// const isRenewalFeatureEnabled =
//   process.env.REACT_APP_RENEWALS_ENABLED === "true";

export default function ProposalsList() {
  const {
    cartContext,
    matchContext,
    freelancerContext,
    compareFlsContext,
    proposalContext,
  } = useContext(CartContext);
  const [, setCart] = cartContext;
  const [, setMatchId] = matchContext;
  const [, setFreelancers] = freelancerContext;
  const [, setCompareFls] = compareFlsContext;

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get("tab");
  const proposalId = queryParams.get("proposalId");

  const { user } = useAuth0();
  const { userId, email, auth0UserId } = user;
  const { firstName, lastName } =
    user[`${process.env.REACT_APP_USER_METADATA_URI}/user_metadata`];
  const roles = user[`${process.env.REACT_APP_USER_METADATA_URI}/roles`] || [];
  const isDealDeskUser = roles?.includes("dealdesk_teams");
  const userName = firstName + " " + lastName;
  const [currentSalesRep, setCurrentSalesRep] = useState({
    label: userName,
    value: auth0UserId,
  });
  const [loadingError, setLoadingError] = useState(false);
  const [value, setValue] = useState(Number(tab) ?? 0);
  const [proposalData, setProposalData] = proposalContext;
  const [freelancersData, setFreelancersData] = useState({});
  const [renewalData, setRenewalData] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const [proposalDataLoaded, setProposalDataLoaded] = useState(false);

  const { data: aeUsers } = useGetSalesRepsQuery({
    variables: {},
  });

  useEffect(() => {
    setCart([]);
    setMatchId(null);
    setFreelancers([]);
    setCompareFls([]);
  }, [setCart, setMatchId, setFreelancers, setCompareFls]);

  if (loadingError) {
    return <ParoError />;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <TopSection />
      <div className="px-8 md:px-12 lg:px-16 flex justify-between">
        <Box className="border-b-1 border-gray-400">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="active" {...a11yProps(0)} />
            <Tab label="renewals" {...a11yProps(1)} />
          </Tabs>
        </Box>
        {process.env.REACT_APP_DEALDESK_ENABLED === "true" &&
          isDealDeskUser && (
            <Grid item xs={3} className="px-2">
              <span className="font-bold">Select Sales Rep</span>
              <Select
                // label="Select Sales Rep"
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                value={currentSalesRep}
                options={(aeUsers?.getSalesReps || [])?.map((u) => ({
                  label: u.name,
                  value: u.id,
                }))}
                onChange={(v) => {
                  setCurrentSalesRep(v);
                  setProposalDataLoaded(false);
                  setProposalData([]);
                  setRenewalData();
                  setCurrentPage(1);
                }}
              />
            </Grid>
          )}
      </div>
      <TabPanel value={value} index={0} className={"p-0"}>
        <ProposalTabContent
          currentSalesRep={currentSalesRep}
          isDealDeskUser={isDealDeskUser}
          setLoadingError={setLoadingError}
          dataLoaded={proposalDataLoaded}
          setDataLoaded={setProposalDataLoaded}
          proposalData={proposalData}
          setProposalData={setProposalData}
          freelancersData={freelancersData}
          setFreelancersData={setFreelancersData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <RenewalsTabContent
          currentSalesRep={currentSalesRep}
          setLoadingError={setLoadingError}
          proposalId={proposalId}
          renewalData={renewalData}
          setRenewalData={setRenewalData}
        />
      </TabPanel>

      <Heap
        appId={process.env.REACT_APP_HEAP_APP_ID}
        userId={`${userId}`}
        userData={{ email: email, userType: 2 }}
      />
    </>
  );
}
